var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contactFormWrapper container mt-5"},[_c('div',{staticClass:"w-100"},[_c('form',{attrs:{"action":"#","id":"contactUs"}},[_c('input',{attrs:{"type":"hidden","name":"formID","value":"cancellationForm"}}),_c('input',{attrs:{"type":"hidden","name":"formTitle","value":"טופס בקשה לביטול הזמנה"}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"row"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"col-sm-6 col-xs-12"},[_c('div',{staticClass:"inputWrap"},[_c('button',{attrs:{"type":"submit","role":"button"}},[_c('span',{attrs:{"data-loading":"עוד רגע :)"}},[_vm._v("שלח")]),_vm._v(" "+_vm._s("\xa0")+_vm._s("\xa0")+_vm._s("\xa0")+" "),_c('i',{staticClass:"fa fa-arrow-left"})])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"textWrap"},[_c('h2',[_vm._v("טופס בקשה לביטול הזמנה")]),_c('p',[_vm._v("טופס הבקשה לביטול הזמנה הינו להזמנה שבוצעה באתר בלבד")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-xs-12"},[_c('div',{staticClass:"inputWrap"},[_c('label',{attrs:{"for":"orderID"}},[_vm._v("מס' הזמנה")]),_c('input',{staticStyle:{"box-shadow":"none"},attrs:{"id":"orderID","name":"orderID","type":"text","role":"textbox"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-xs-12"},[_c('div',{staticClass:"inputWrap"},[_c('label',{attrs:{"for":"fullName"}},[_c('span',[_vm._v("*")]),_vm._v("שם מלא באנגלית")]),_c('input',{staticStyle:{"box-shadow":"none"},attrs:{"id":"fullName","name":"fulltName","type":"text","required":"","role":"textbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-xs-12"},[_c('div',{staticClass:"inputWrap"},[_c('label',{attrs:{"for":"idNum"}},[_c('span',[_vm._v("*")]),_vm._v("מס' תעודת זהות")]),_c('input',{staticStyle:{"box-shadow":"none"},attrs:{"id":"idNum","name":"idNum","type":"text","required":"","role":"textbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-xs-12"},[_c('div',{staticClass:"inputWrap"},[_c('label',{attrs:{"for":"phone"}},[_c('span',[_vm._v("*")]),_vm._v("טלפון (שהוזן בהזמנה)")]),_c('input',{staticStyle:{"box-shadow":"none"},attrs:{"id":"phone","name":"phone","type":"tel","required":"","role":"textbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-xs-12"},[_c('div',{staticClass:"inputWrap"},[_c('label',{attrs:{"for":"email"}},[_c('span',[_vm._v("*")]),_vm._v("אימייל")]),_c('input',{staticStyle:{"box-shadow":"none"},attrs:{"id":"email","name":"email","type":"email","required":"","role":"textbox"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"inputWrap p-3"},[_c('label',{attrs:{"for":"msgContent"}},[_c('span',[_vm._v("*")]),_vm._v("סיבת הבקשה לביטול")]),_c('textarea',{staticStyle:{"margin":"0px","height":"146px","resize":"none"},attrs:{"name":"contact_content","id":"msgContent","cols":"30","rows":"10","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-xs-12"},[_c('div',{staticClass:"g-recaptcha",attrs:{"data-sitekey":"6LcvaBAUAAAAAB-geW2IyrWcENZ69euhYPzexHTR"}},[_c('div',{staticStyle:{"width":"304px","height":"78px"}},[_c('div',[_c('iframe',{attrs:{"title":"reCAPTCHA","src":"https://www.google.com/recaptcha/api2/anchor?ar=1&k=6LcvaBAUAAAAAB-geW2IyrWcENZ69euhYPzexHTR&co=aHR0cHM6Ly93d3cuZmx5aW5nLmNvLmlsOjQ0Mw..&hl=en&v=UrRmT3mBwY326qQxUfVlHu1P&size=normal&cb=k1uofc9jkb2k","width":"304","height":"78","role":"presentation","name":"a-f96usrklq2aw","frameborder":"0","scrolling":"no","sandbox":"allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"}})]),_c('textarea',{staticClass:"g-recaptcha-response",staticStyle:{"width":"250px","height":"40px","border":"1px solid rgb(193, 193, 193)","margin":"10px 25px","padding":"0px","resize":"none","display":"none"},attrs:{"id":"g-recaptcha-response","name":"g-recaptcha-response"}})]),_c('iframe',{staticStyle:{"display":"none"}})])])
}]

export { render, staticRenderFns }